<template>
	<ZyroModal
		height="100%"
		max-width="758px"
		max-height="342px"
		position="bottom"
		class="published-modal"
		@close-modal="closePublishModal"
	>
		<div class="published-modal__wrapper">
			<h4 class="published-modal__title z-h4">
				{{ $t('builder.curatedTemplates.published.title') }}
			</h4>
			<p class="published-modal__text z-body-small">
				{{ $t("builder.curatedTemplates.published.text") }}
			</p>
			<ZyroButton
				class="published-modal__button"
				theme="primary"
				color="plump-purple"
				size="xs"
				@click="viewTemplate"
			>
				{{ $t('common.viewTemplate') }}
			</ZyroButton>
		</div>
	</ZyroModal>
</template>

<script>
import { mapState } from 'vuex';

import { WWW_REDIRECT_PATHS } from '@/constants';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { useRedirects } from '@/use/useRedirects';

const { DESIGNER_DRAFTS } = WWW_REDIRECT_PATHS;

export default {
	setup() {
		const { redirectToWWW } = useRedirects();

		return { redirectToWWW };
	},
	computed: mapState(['domain']),
	methods: {
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		viewTemplate() {
			window.open(`//${this.domain}`);
			this.closeModal('DesignerAfterPublishModal');
			this.closePublishModal();
		},
		closePublishModal() {
			this.redirectToWWW({ path: DESIGNER_DRAFTS });
		},
	},
};
</script>

<style lang="scss" scoped>
.published-modal {
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 64px 66px 40px;
	}

	&__title {
		max-width: 578px;
		margin-bottom: 24px;
		text-align: center;

		@include mobile-view {
			margin-bottom: 26px;
		}
	}

	&__text {
		max-width: 578px;
		margin-bottom: 47px;
		text-align: center;

		@include mobile-view {
			margin-bottom: 55px;
		}
	}

	&__button {
		margin: 0;
		overflow: hidden;
	}
}
</style>
